:root {
    --primary: #f43f5e;
    --primary-hover: #e11d48;
    --secondary: #e2e8f0;
    --secondary-hover: #cbd5e1;
    --text: #1e293b;
    --background: #ffffff;
    --border: #e2e8f0;
}
 
body {
    background-color: var(--background);
    color: var(--text);
    line-height: 1.6;
}

.container {
    max-width: 500px;
    margin: 40px auto;
    padding: 30px;
    border: 1px solid var(--border);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: var(--primary);
}

.warning-box {
    background-color: rgba(244, 63, 94, 0.1);
    border-left: 4px solid var(--primary);
    padding: 15px;
    margin-bottom: 25px;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

input[type="text"] {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--border);
    border-radius: 4px;
    font-size: 16px;
}

input[type="text"]:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 2px rgba(244, 63, 94, 0.2);
}

.checkbox-group {
    margin-bottom: 25px;
}

.checkbox-group label {
    display: flex;
    align-items: center;
    font-weight: normal;
}

.checkbox-group input {
    margin-right: 10px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

button {
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.btn-cancel {
    background-color: var(--secondary);
    color: var(--text);
    flex: 1;
}

.btn-cancel:hover {
    background-color: var(--secondary-hover);
}

.btn-delete {
    background-color: var(--primary);
    color: white;
    flex: 1;
}

.btn-delete:hover {
    background-color: var(--primary-hover);
}

.btn-delete:disabled {
    background-color: #f8a8b6;
    cursor: not-allowed;
}

@media (max-width: 600px) {
    .container {
        padding: 20px;
        margin: 20px auto;
    }

    .button-group {
        flex-direction: column;
    }
}