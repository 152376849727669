body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
    background: #f8f8f8;
  }
  .bborder{
    width: 90%;
    height: 1px;
    background:gray;
    margin:auto;

  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f8f8f8;
    position: relative;
  }
  
  .logo img {
    height: 50px;
    width: 150px;
    object-fit: cover;
  }
  
  .nav {
    display: flex;
    gap: 20px;
  }
  
  .nav a {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
  }
  
  .nav a:hover {
    color: green;
  }
  
  .hamburger {
    display: none;
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
    color: black;
  }
  
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto;
    max-width: auto; 
    padding: 0 30px 0 30px;
  }
  
  .phone-image {
    padding: 2px;
    margin: 20px auto;
  }
  
  .phone-image img {
    max-width: 100%;
    height: auto;
  }
  
  
  
  .quote-section {
    text-align: center;
    max-width: 65%;
    margin: 20px auto;
  }
  
  .quote {
    font-size: 22px;
    font-weight: bold;
  }
  
  /* .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto;
    max-width: auto;
    border-bottom: 2px solid black;
    padding: 0 30px 0 30px;
  }
  
  .phone-image {
    padding: 2px;
    margin: 20px auto;
  }
  
  .phone-image img {
    max-width: 100%;
    height: auto;
  }
  
  .quote-section {
    text-align: center;
    max-width: 90%;
    margin: 20px auto;
  }
  
  .quote {
    font-size: 20px;
    font-weight: bold;
  } */
  
  .google-play {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .google-play img {
    /* width: 150px;
    height: auto; */
  
    width: 180px;
    height: 80px;
    object-fit: cover; 
  
  }
  
  .content {
    max-width: 1200px;
    margin: auto;
    padding: 30px;
    text-align: center; 
  }
  
  /* .content-image {
    padding: 2px;
    width: 300px;  
    height: 200px; 
    display: flex;
    justify-content: center; 
    align-items: center;
    overflow: hidden; 
    background-color: #f0f0f0; 
  }
  
  .content-image img {
    max-width: 100%;  
    max-height: 100%; 
    object-fit: contain;
  } */
  
  .content-image {
    padding: 5px;
  }
  .content-image img {
    width: 300px;
    height: 200px;
    object-fit: contain;
  }
  
  .content-text {
    font-size: 18px;
    /* font-weight: bold; */
    text-align: center;
    line-height: 1.2;
  }
  
  .content h2 {
    font-size: 25px;
    font-weight: bold;
    margin: 2px;
  }
  
  .footer {
    background: #f8f8f8;
    padding: 30px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: auto;
    text-align: left;
  }
  
  .about-founder h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .about-founder p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  }
  
  .footer-info {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    justify-content: center;
  }
  
  .footer-founder-separator {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
  }
  
  .social-media {
    text-align: center;
    margin-top: 15px;
  }
  
  .social-media h3 {
    font-size: 16px;
    font-weight: bold;
  }
  
  .social-media a {
    margin: 5px;
    display: inline-block;
  }
  
  .social-media img {
    width: 40px;
    height: 40px;
  }
  
  @media (max-width: 768px) {
    .nav {
      display: none;
      flex-direction: column;
      background: #d6f1b1;
      position: absolute;
      top: 60px;
      right: 20px;
      width: 200px;
      text-align: center;
      border-radius: 8px;
      padding: 10px;
    }
  
    .nav a {
      display: block;
      padding: 10px;
    }
  
    .hamburger {
      display: block;
    }
    .top-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 5px auto;
      padding: 0 30px;
    }

  
  
    .phone-image {
      padding: 2px;
      margin: 20px auto;
    }
  
    .phone-image img {
      max-width: 100%;
      height: auto;
    }
  
    .quote-section {
      text-align: center;
      max-width: 90%;
      margin: 20px auto;
    }
  
    .quote {
      font-size: 20px;
      font-weight: bold;
    }
  
    .footer-founder-separator {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
    }
    .footer-info {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      justify-content: center;
    }
  }
  