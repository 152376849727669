@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
*,
::after,
::before {
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Lato", sans-serif;
}
html{
  overflow-y: auto !important;
}
:root {
  --bg-color: #000;
  --primary-color: #006ce0;
  --border-radius: 6px;
  --color: #2b82df;
  /* --box-shadow: #0b488a 5px 0px 50px 0px; */
}

.image_container {
	position: relative;
	width: 200px;
	height: auto;
	display: flex;
  margin-right: 2px;
  margin-bottom: 2px;
}

.image_container_button {
	padding: 5px !important;
	position: absolute !important;
	top: 0 !important;
}
.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	margin-left: 1rem;
}
.addicon:hover{
  background: #5d23c0;
}
.addicon {
	width: 28px;
	height: 28px;
	background: #5b1bcc;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
  cursor: pointer;
}
.addbuttonwrapper{
}
.notification-container--center {
	top: 20px;
	height: 60px;
	pointer-events: none;
}
.sidebar {
  z-index: 9005;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  background: var(--bg-color);
  transition: left 0.5s ease;
  -webkit-transition: left 0.5s ease;
  -moz-transition: left 0.5s ease;
  -ms-transition: left 0.5s ease;
  -o-transition: left 0.5s ease;
}
.sidebar ul {
  list-style: none;
  margin-top: 0;
  height: 100%;
}

.sidebar ul li {
  margin-top: 1rem;
  padding: 0.3rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar ul li.active {
  background: var(--primary-color);
  border-bottom-left-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  transition: background 0.5s linear;
  box-shadow: rgba(20, 114, 238, 0.918) 0px 2px 10px 0px;
}

.sidebar li .icon {
  margin-right: 1rem;
  padding: 0.5rem;
}
.sidebar li a {
  text-decoration: none;
  font-size: 1.3rem;
  color: white;
  display: flex;
  align-items: center;
  width:100%;
}
.sidebar li a:hover {
  margin-left: 2px;
}
.navTitle {
	background: #7900FF;
	height: 45px;
	display: flex;
	align-items: center;
	font-size: 20px;
	color: #fff;
	width: 100%;
}
ol, ul {
	padding-left: 20px !important;
}
.close-icon {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2.2rem;
  margin: 0;
  cursor: pointer;
}
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto !important;
	max-height: 100%;
}
.hamburger-menu {
  position: relative;
  z-index: 9005;
  margin-top: 7px;
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
}

body .toggle.sidebar {
  left: -300px;
}
body.toggle.section {
  margin-left: 0;
  width: 100%;
}

.columns{
  flex-direction: row !important;
  @media screen and (max-width: 900px) {
    flex-direction: column !important;
  }
}
